@import '../../../../stylesheets/variables.postcss';
@import '../../../../stylesheets/utils/mixins.postcss';

.CateringCta {
  border: 1px solid $mediumGrey;
  display: flex;
  flex-direction: column;

  @mixin above $medium {
    flex-direction: row;

    img {
      max-width: 33%;
    }
  }
}

.CateringCta_content {
  padding: 2rem;
}