@import '../../../../stylesheets/variables.postcss';
@import '../../../../stylesheets/utils/mixins.postcss';
@import '../../../../stylesheets/shared/content/fonts.postcss';

.CuisineLink {
  @mixin phosphate;
  padding: .5rem;

  & > a {
    height: 100%;
    color: white !important;
    background: $darkBlue;
    border-radius: 20px;
    text-align: center;
    padding: 2rem 1rem;
    display: block;

    &:hover {
      background: $darkBlueHover;
    }
  }

  @mixin above $medium {
    flex: 20%;

    & > a {
      padding: 1rem 1rem;
    }
  }
}

.CuisineLinkContainer {
  margin: -.5rem;
  flex-direction: column;
  flex-wrap: wrap;

  @mixin above $medium {
    flex-direction: row;
  }
}

.CuisineLink_emoji {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 1rem;
}