@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.List_items {
  columns: 2;

  @mixin above $medium {
    columns: 4;
  }
}

.List_item {
  display: block;
  margin-bottom: 10px;

  & > a {
    text-decoration: underline;
    color: black;

    &:hover {
      opacity: 50%;
    }
  }
}